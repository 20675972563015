.Page {
  text-align: center;
}

.Page-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #CCC;
}

.Logo {
  width: 600px;
  height: 200px;
}

.Version {
  color: #383838;
  font-size: 14px;
  margin-bottom: 30px !important;
}

.LoginButton {
  --bs-btn-line-height: 1.3333333 !important;
  --bs-btn-border-radius: 3px !important;
  --bs-btn-font-size: 14px !important;
  --bs-btn-padding-x: 16px !important;
  --bs-btn-padding-y: 10px !important;
  width: 140px;
}
