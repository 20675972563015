.lablink {
  text-decoration: none;
  color: var(--bs-nav-link-color);
}

.lablink:hover {
  color: var(--bs-navbar-hover-color);
}

.nav-link-pending {
  color: #E5D5D5 !important;
}

.nav-link-disabled {
  color: #9D0000 !important;
}
