:root {
  --bs-nav-link-font-size: 14px;
}

.App-logo {
  width: 80px;
  padding-bottom: 2px;
}
.px-5px {
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.card {
  width: auto;
  margin: 100px 0;
  padding-left: 60px;
  padding-right: 60px;
  line-height: 24px;
}
body::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
#basic-navbar-nav {
  height: 27px;
}
.progress {
  height: 1px !important;
  margin-top: -1px;
  border-radius: 0 !important;
  border:0;
}
.embed {
  line-height: 0px;
}
.embed iframe {
  min-height: calc(100vh - 28px); /* 27px header + 1px progress */
  width: 100%;
  height: 100%;
}
.cursor-pointer {
  cursor: pointer;
}
.fadeOut {
  opacity:0;
  transition: opacity 1000ms;
}
.fadeIn {
  opacity:1;
  transition: opacity 1000ms;
}

.bi {
  background-color: #EEE;
}
.home-heading {
  font-size: 22px;
  font-weight: bold;
}
.card-text, .card-link {
  font-size: 19px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
